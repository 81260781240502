import React from 'react';
import './ArchitectureViewer.css';

const ArchitectureViewer = ({ architecture }) => {
    return (
        <div className="architecture-viewer">
            <h2>{architecture.title}</h2>
            <img src={process.env.PUBLIC_URL + `/images/${architecture.imageFileName}`} alt={`${architecture.title} architecture`} />
            <p>{architecture.description}</p>
            <a href={architecture.detailsUrl} target="_blank" rel="noopener noreferrer">View Details</a>
            <br></br>
            <br></br>
            <br></br>
        </div>
    );
};

export default ArchitectureViewer;
