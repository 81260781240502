import React, { useState } from 'react';
import ArchitectureMenu from './components/ArchitectureMenu';
import ArchitectureViewer from './components/ArchitectureViewer';
import './App.css';

function App() {
    const architectures = [
        {
            title: 'Cloud-Based Image Recognition Service',
            description: 'A globally accessible service on AWS using CloudFormation for infrastructure management.',
            imageFileName: 'architect.png',
            detailsUrl: 'http://www.visionai.firstcode.me'
        },
        {
            title: 'To Do App',
            description: 'A simple To Do app deployed on AWS cloud with certificate',
            imageFileName: 'todo.png',
            detailsUrl: 'http://aws.firstcode.me'
        },
        {
            title: 'Backend Development for Food Ordering Application',
            description: 'API development using Node.js and Express, with cloud deployment architecture.',
            imageFileName: 'yorkFoodsArch.png'
        }
    ];

    const [selectedArchitecture, setSelectedArchitecture] = useState(architectures[0]);

    return (
        <div className="App">
            <header className="App-header">
                <h1>Projects by Mohit Kumar</h1>
            </header>
            <ArchitectureMenu architectures={architectures} onSelect={index => setSelectedArchitecture(architectures[index])} />
            <ArchitectureViewer architecture={selectedArchitecture} />
        </div>
    );
}

export default App;
