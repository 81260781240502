import React from 'react';
import './ArchitectureMenu.css';

const ArchitectureMenu = ({ architectures, onSelect }) => {
    return (
        <div className="architecture-menu">
            {architectures.map((architecture, index) => (
                <button key={index} onClick={() => onSelect(index)}>
                    {architecture.title}
                </button>
            ))}
        </div>
    );
};

export default ArchitectureMenu;
